import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import RichTextToReact from "rich-text-to-react"
import { INLINES } from "@contentful/rich-text-types"
import LocationsMap, { LocationsMapHeader } from "../LocationsMap"
import Hero from "../Hero"
import Card from "../Card"
import Cta from "./components/Cta"
import ServicesBanner from "../ServicesBanner"
import PreFooter from "../PreFooter"
import VRTour from "./components/VRTour"
import * as styles from "./styles.module.less"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, key, next) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a key={key} href={uri} {...attr}>
          {next(node.content, key, next)}
        </a>
      )
    },
  },
}

const HomePage = () => {
  const {
    contentfulHomePage: {
      hero: {
        heroImage: {
          file: { url },
          description,
        },
        headline,
        eyebrow,
      },
      overview: { raw: overViewaw },
      overviewFeatures = [],
      callToAction: { raw: callToActionRaw },
      approach: {
        title: approachTitle,
        image: {
          description: approachAlt,
          file: { url: approachSrc },
        },
        body: { raw: approachRaw },
      },
      locations: { raw: locationsRaw },
      preFooter = [],
    },
  } = useStaticQuery(
    graphql`
      {
        contentfulHomePage(slug: { eq: "home" }) {
          hero {
            heroImage {
              file {
                url
              }
              description
            }
            headline
            eyebrow
          }
          overview {
            raw
          }
          overviewFeatures {
            id
            title
            image {
              description
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
          callToAction {
            raw
          }
          approach {
            title
            body {
              raw
            }
            image {
              file {
                url
              }
              description
            }
          }
          locations {
            raw
          }
          preFooter {
            id
            resourceTitle
            resourceCopy {
              raw
            }
          }
        }
      }
    `
  )

  const overviewImages = overviewFeatures.map((feature) => {
    const {
      id,
      title: caption,
      image: {
        description: alt,
        file: {
          url: src,
          details: {
            image: { height, width },
          },
        },
      },
    } = feature
    return (
      <OverviewImage
        key={id}
        src={src}
        alt={alt}
        height={height}
        width={width}
        caption={caption}
      />
    )
  })

  const resources = preFooter.map(
    ({ id, resourceTitle: title, resourceCopy: { raw = "" } }) => {
      return {
        id,
        title,
        body: (
          <RichTextToReact document={JSON.parse(raw)} options={renderOptions} />
        ),
      }
    }
  )

  return (
    <div className={styles.HomePage}>
      <Hero
        headline={headline}
        image={url}
        alt={description}
        eyebrow={eyebrow}
      />
      <section className={styles.body}>
        <div className={styles.overview}>
          <RichTextToReact
            document={JSON.parse(overViewaw)}
            options={renderOptions}
          />
          <div className={styles.overviewImages}>{overviewImages}</div>
        </div>
        <div className={styles.employersCard}>
          <Card>
            <div>
              <RichTextToReact
                document={JSON.parse(callToActionRaw)}
                options={renderOptions}
              />
              <div className={styles.ctas}>
                <Cta text="For employees" link="/patients" type="bordered" />
                <div className={styles.spacer}></div>
                <Cta text="For employers" link="/employers" />
              </div>
            </div>
          </Card>
        </div>

        <div className={styles.twoColumn}>
          <img src={approachSrc} alt={approachAlt} />
          <div className={styles.copy}>
            <h2>{approachTitle}</h2>
            <RichTextToReact
              document={JSON.parse(approachRaw)}
              options={renderOptions}
            />
          </div>
        </div>
        <ServicesBanner />
        <div className={styles.learnMoreCta}>
          <Cta link="/services">
            Learn more{" "}
            <span className={styles.hideSmall}>about our services</span>
          </Cta>
        </div>
      </section>

      <VRTour />

      <section className={styles.locations}>
        <LocationsMapHeader />
        <LocationsMap />
        <div className={styles.copy}>
          <div className={styles.links}>
            <Link to="/Portland">Portland</Link>
            <Link to="/Beaverton">Beaverton</Link>
            <Link to="/Vancouver">Vancouver</Link>
            <Link to="/Salem">Salem</Link>
          </div>
          <RichTextToReact
            document={JSON.parse(locationsRaw)}
            options={renderOptions}
          />
        </div>
      </section>
      <PreFooter resources={resources} />
    </div>
  )
}

export default HomePage

const OverviewImage = ({ src, alt, height, width, caption }) => {
  return (
    <div className={styles.imageWrapper}>
      <figure>
        <img src={src} alt={alt} height={height} width={width} />
        <figcaption>{caption}</figcaption>
      </figure>
    </div>
  )
}
