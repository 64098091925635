import React from "react"
import vr from "./img/VR.jpg"

import * as styles from "./styles.module.less"

const VRTour = () => {
  return (
    <section className={styles.vrTour}>
      <div className={styles.twoColumn}>
        <a
          href="https://kpvr.org/kaiser-permanente-on-the-job/"
          target="_blank"
          rel="noreferrer"
          className={styles.img}
        >
          <img src={vr} alt="VR Tour" />
        </a>
        <div className={styles.copy}>
          <div className={styles.subhead}>
            <h2>Experience it for yourself</h2>
            <p>
              Take an exclusive{" "}
              <a
                href="https://kpvr.org/kaiser-permanente-on-the-job/"
                target="_blank"
                rel="noreferrer"
              >
                virtual tour
              </a>{" "}
              of our facilities and learn more about the value KPOJ can provide
              your business.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VRTour
