import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import HomePage from "../components/HomePage"

const IndexPage = () => {
  const {
    contentfulHomePage: {
      title,
      seo: { title: seoTitle, description, language },
    },
  } = useStaticQuery(
    graphql`
      {
        contentfulHomePage(slug: { eq: "home" }) {
          title
          seo {
            language
            title
            description
          }
        }
      }
    `
  )
  return (
    <Layout>
      <Seo
        title={title || seoTitle}
        description={description}
        lang={language}
      />
      <HomePage />
    </Layout>
  )
}

export default IndexPage
